import React, { useState, useEffect, useRef } from "react";
import styles from "./Countdown.module.scss";
import { gsap } from "gsap";

export const Countdown: React.FC<{ date: Date }> = ({ date }) => {
  const countdownDate = new Date(date).getTime();
  const shadowRef1 = useRef<HTMLDivElement>(null);
  const shadowRef2 = useRef<HTMLDivElement>(null);

  const digitRefs = {
    days: [useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null)],
    hours: [useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null)],
    minutes: [useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null)],
    seconds: [useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null)],
  };

  const formatNumber = (num: number) => String(num).padStart(2, "0");

  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const difference = countdownDate - now;

    if (difference <= 0) {
      return { days: "00", hours: "00", minutes: "00", seconds: "00" };
    }

    return {
      days: formatNumber(Math.floor(difference / (1000 * 60 * 60 * 24))),
      hours: formatNumber(
        Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      ),
      minutes: formatNumber(
        Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
      ),
      seconds: formatNumber(Math.floor((difference % (1000 * 60)) / 1000)),
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();

      Object.keys(newTimeLeft).forEach((key) => {
        const oldValue = timeLeft[key as keyof typeof timeLeft];
        const newValue = newTimeLeft[key as keyof typeof newTimeLeft];

        oldValue.split("").forEach((oldDigit, index) => {
          const newDigit = newValue[index];

          if (oldDigit !== newDigit) {
            gsap.fromTo(
              digitRefs[key as keyof typeof digitRefs][index].current,
              { y: "-100%", opacity: 0 },
              {
                y: "0%",
                opacity: 1,
                duration: 0.8,
                ease: "elastic(1, .5)",
              }
            );
          }
        });
      });

      setTimeLeft(newTimeLeft);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  // GSAP Background Animation with Rotation, Opacity, Scale & Blur
  useEffect(() => {
    const animateShadow = (
      element: HTMLDivElement | null,
      xRange: number,
      yRange: number,
      durationRange: number[]
    ) => {
      if (element) {
        gsap.to(element, {
          x: () => gsap.utils.random(-xRange, xRange),
          y: () => gsap.utils.random(-yRange, yRange),
          scale: () => gsap.utils.random(0.8, 1.4), // Scale effect
          opacity: () => gsap.utils.random(0.6, 0.8), // Opacity effect
          rotation: () => gsap.utils.random(-30, 30), // Rotation effect
          filter: () => `blur(${gsap.utils.random(30, 60)}px)`, // Dynamic blur effect
          duration: gsap.utils.random(durationRange[0], durationRange[1]),
          ease: "power1.inOut",
          repeat: -1, // Infinite loop
          yoyo: true, // Smooth transition back and forth
        });
      }
    };

    const isMobile = window.innerWidth <= 768;

    if (isMobile) {
      animateShadow(shadowRef1.current, 150, 20, [1, 2]);
      animateShadow(shadowRef2.current, 150, 20, [1, 2]);
    } else {
      animateShadow(shadowRef1.current, 200, 20, [3, 5]);
      animateShadow(shadowRef2.current, 200, 20, [3, 5]);
    }
  }, []);

  return (
    <div className={styles.countdown}>
      <div ref={shadowRef1} className={styles.movingShadow}></div>
      <div ref={shadowRef2} className={styles.movingShadow}></div>

      <div className={styles.countdown__container}>
        <div className={styles.countdown__container__item}>
          <div className={styles.album__cover}>
            <img
              src="https://cdn.sanity.io/images/m4jodx2s/production/383483f1628d9e619d55da0f111effdc975f02f9-1000x1000.jpg"
              alt="Album Cover"
            />
          </div>
        </div>
        {["days", "hours", "minutes", "seconds"].map((key) => (
          <React.Fragment key={key}>
            <div className={styles.countdown__container__item}>
              <div className={styles.countdown__container__item__number}>
                <div className={styles.digitWrapper}>
                  <span ref={digitRefs[key as keyof typeof digitRefs][0]}>
                    {timeLeft[key as keyof typeof timeLeft][0]}
                  </span>
                </div>
                <div className={styles.digitWrapper}>
                  <span ref={digitRefs[key as keyof typeof digitRefs][1]}>
                    {timeLeft[key as keyof typeof timeLeft][1]}
                  </span>
                </div>
              </div>
              <div className={styles.countdown__container__item__text}>
                {key.charAt(0).toUpperCase() + key.slice(1)}
              </div>
            </div>
            {key !== "seconds" && (
              <div
                className={`${styles.countdown__container__item} ${styles.dividerLine}`}
              ></div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
