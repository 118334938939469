import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import styles from "./SaveIcon.module.scss";

export const SaveIcon: React.FC<{
  checked?: boolean;
}> = ({ checked = false }) => {
  const verticalRef = useRef<HTMLDivElement | null>(null);
  const horizontalRef = useRef<HTMLDivElement | null>(null);
  const iconContainer = useRef<HTMLDivElement | null>(null);
  const tl = useRef<gsap.core.Timeline | null>(null);

  useEffect(() => {
    if (!verticalRef.current || !horizontalRef.current) {
      console.error("Refs are missing!");
      return;
    }

    tl.current = gsap.timeline({ paused: true });
    tl.current
      .to(
        verticalRef.current,
        {
          scaleY: 0,
          opacity: 0,
          duration: 0.8,
          ease: "elastic.inOut(1, 0.7)", // ✅ Elastic bounce effect
        },
        0
      )
      .to(
        horizontalRef.current,
        {
          height: 5, // ✅ Makes it look more like a checkmark
          rotate: 495, // ✅ Turns into a diagonal line for checkmark
          transformOrigin: " center left ",
          borderWidth: "3px 3px 0px 0px",
          borderStyle: "solid",
          borderColor: "#0000b2",
          background: "transparent",
          borderRadius: "1px",
          duration: 0.8,
          ease: "elastic.inOut(1, 0.7)", // ✅ Elastic bounce effect
        },
        0
      )
      .to(
        iconContainer.current,
        {
          backgroundColor: "white",
          duration: 0.8,
          ease: "elastic.inOut(1, 0.7)", // ✅ Elastic bounce effect
        },
        0
      );
  }, []);

  useEffect(() => {
    if (!tl.current) {
      console.error("❌ GSAP timeline is not initialized!");
      return;
    }

    if (checked) {
      tl.current.play();
    } else {
      tl.current.reverse();
    }
  }, [checked]); // ✅ Reacts to prop changes

  return (
    <div ref={iconContainer} className={styles.iconContainer}>
      <div ref={verticalRef} className={styles.plusVertical} />
      <div ref={horizontalRef} className={styles.plusHorizontal} />
    </div>
  );
};
