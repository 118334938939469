import React, { useRef, useEffect, useState } from "react";
import { gsap } from "gsap";
import styles from "./SongRegister.module.scss";
import { client } from "../../sanityClient";
import { SONG_REGISTER_QUERY } from "../../queries/songRegisterQueries";
import { useParams } from "react-router-dom";
import { fetchPostData } from "../../_utils";
import { SimpleSubscriber } from "../../components/SimpleSubscriber/SimpleSubscriber.component";
import { Helmet } from "react-helmet";
import { formatDateWithOrdinal } from "../../_helpers";
import { RegularBlinker } from "../../components/RegularBlinker/RegularBlinker.component";

const setFullHeight = () => {
  document.documentElement.style.setProperty(
    "--browser-height",
    `${window.innerHeight}px`
  );
};

window.addEventListener("resize", setFullHeight);
setFullHeight();

const preloadMedia = (sources: string[]): Promise<void[]> => {
  return Promise.all(
    sources.map((src) => {
      return new Promise<void>((resolve, reject) => {
        const ext = src.split(".").pop() as string;
        if (ext === "jpg" || ext === "png" || ext === "gif") {
          const img = new Image();
          img.src = src;
          img.onload = () => resolve();
          img.onerror = () => reject(new Error(`Failed to load image: ${src}`));
        } else if (ext === "mp4" || ext === "webm") {
          const video = document.createElement("video");
          video.src = src;
          video.oncanplaythrough = () => resolve();
          video.onerror = () =>
            reject(new Error(`Failed to load video: ${src}`));
        } else {
          resolve(); // Skip unsupported types like audio
        }
      });
    })
  );
};

export const SongRegister = () => {
  const [post, setPost] = useState<any>(null);
  const [currentStep, setCurrentStep] = useState(0); // Set initial state to 0
  const [totalSeconds, setTotalSeconds] = useState(0);
  const [currentSeconds, setCurrentSeconds] = useState(0);
  const audioRef = useRef<HTMLAudioElement>(null);
  const videoRef = useRef<HTMLImageElement>(null);
  const { slug } = useParams();
  const [isAudioReady, setIsAudioReady] = useState(false); // Track audio setup completion
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const metaTag = document.querySelector('meta[name="theme-color"]');

    document.body.style.backgroundColor = "#0000b2";

    if (metaTag) {
      metaTag.setAttribute("content", "#0000b2");
    }
    // Lock the body scroll
    document.body.classList.add(styles.noScroll);

    // Cleanup function to remove the class when the component unmounts
    return () => {
      document.body.classList.remove(styles.noScroll);
    };
  }, []);

  // Fetch post data
  useEffect(() => {
    if (!slug) {
      return;
    }

    const fetchPost = async () => {
      try {
        const result = await fetchPostData(client, slug, SONG_REGISTER_QUERY);
        setPost(result);
      } catch (error) {}
    };

    fetchPost();
  }, [slug]);

  useEffect(() => {
    const setupAudio = async () => {
      if (!audioRef.current) {
        return;
      }

      const audio = audioRef.current;

      const handleLoadedMetadata = () => {
        if (!audioRef.current) {
          return;
        }

        setTotalSeconds(audioRef.current.duration); // Set total duration in state
        setIsAudioReady(true); // Set audio ready state
      };

      const handleTimeUpdate = () => {
        setCurrentSeconds(audio.currentTime);
      };

      const handleAudioEnded = () => {
        gsap.to(`.${styles.listening}`, {
          opacity: 0,
          duration: 1,
          onComplete: () => setCurrentStep(3),
        });
      };

      audio.addEventListener("loadedmetadata", handleLoadedMetadata);
      audio.addEventListener("timeupdate", handleTimeUpdate);
      audio.addEventListener("ended", handleAudioEnded);

      return () => {
        audio.removeEventListener("loadedmetadata", handleLoadedMetadata);
        audio.removeEventListener("timeupdate", handleTimeUpdate);
        audio.removeEventListener("ended", handleAudioEnded);
      };
    };

    setupAudio();
  }, [audioRef.current]);

  // Transition to final screen
  useEffect(() => {
    if (currentSeconds >= totalSeconds && totalSeconds > 0) {
      gsap.to(`.${styles.listening}`, {
        opacity: 0,
        duration: 1,
        onComplete: () => setCurrentStep(3),
      });
    }
  }, [currentSeconds, totalSeconds]);

  const handleIntroEnd = () => setCurrentStep(1);
  const handleSubscribe = () => {
    setCurrentStep(2);
    const audio = audioRef.current;
    if (audio) {
      audio.play().catch((error) => console.error("Audio play error:", error));
    }
  };

  useEffect(() => {
    const loadPostData = async () => {
      if (!slug) {
        setIsLoading(false); // Ensure loading state doesn't hang indefinitely
        return;
      }

      try {
        const result = await fetchPostData(client, slug, SONG_REGISTER_QUERY);
        setPost(result);

        const mediaSources = [
          result.coverImage?.asset?.url,
          result.video?.asset?.url,
          result.songPreview?.asset?.url,
        ].filter(Boolean); // Exclude undefined or null values

        await preloadMedia(mediaSources);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    loadPostData();
  }, [slug]);

  if (isLoading) {
    return <div className={styles.loadingScreen}></div>;
  }

  return (
    <div className={styles.songRegister}>
      <Helmet>
        <meta name="theme-color" content="#0000b2" />
        <style>
          {`
            body, html {
              background-color: #0000b2;
            }
          `}
        </style>
      </Helmet>
      <audio
        src={post?.songPreview?.asset?.url}
        ref={audioRef}
        preload="metadata"
        style={{ display: "none" }}
        onLoadedMetadata={() => {
          if (audioRef.current) {
            setTotalSeconds(audioRef.current.duration);
            setIsAudioReady(true); // Set audio ready state
          }
        }}
      />
      {currentStep === 0 && (
        <IntroScreen
          post={post}
          onIntroEnd={handleIntroEnd}
          videoRef={videoRef}
        />
      )}
      {currentStep === 1 && (
        <RegisterScreen
          post={post}
          onSubscribe={handleSubscribe}
          isAudioReady={isAudioReady}
        />
      )}
      {currentStep === 2 && (
        <SubscriptionSuccessScreen
          key="subscription-success"
          audioRef={audioRef}
          currentSeconds={currentSeconds}
          totalSeconds={totalSeconds}
          post={post}
          onFadeIn={() => {
            gsap.fromTo(
              `.${styles.listening}`,
              { opacity: 0 },
              { opacity: 1, duration: 1 }
            );
          }}
        />
      )}
      {currentStep === 3 && <FinalScreen post={post} />}
    </div>
  );
};

const IntroScreen: React.FC<{
  post: any;
  onIntroEnd: () => void;
  videoRef: React.RefObject<HTMLImageElement>;
}> = ({ post, onIntroEnd, videoRef }) => {
  const headingRef = useRef<HTMLHeadingElement>(null);
  const paragraphRef = useRef<HTMLParagraphElement>(null);
  const songRegisterRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const tl = gsap.timeline({
      onComplete: () => {
        onIntroEnd(); // Ensure the step transition happens
      },
    });

    tl.fromTo(videoRef.current, { display: "none" }, { display: "block" })
      .fromTo(videoRef.current, { opacity: 0 }, { opacity: 1, duration: 2 })
      .fromTo(headingRef.current, { display: "none" }, { display: "flex" })
      .fromTo(
        paragraphRef.current,
        { opacity: 0, y: 20 },
        { opacity: 1, y: 0, duration: 1.5, delay: 1 }
      )

      .to([headingRef.current, paragraphRef.current], {
        opacity: 0,
        duration: 0.5,
        ease: "power1.inOut",
        delay: 2,
      })
      .fromTo(
        songRegisterRef.current,
        { opacity: 1 },
        { opacity: 0, duration: 1 }
      );

    return () => {
      tl.kill();
    };
  }, [onIntroEnd]);

  return (
    <div ref={songRegisterRef} className={`${styles.songRegister}`}>
      <div className={styles.text}>
        <h1 ref={headingRef}>{post?.title}</h1>
        <p ref={paragraphRef}>Out {formatDateWithOrdinal(post.releaseDate)}</p>
      </div>
      <img
        className={`${styles.bgGif} ${styles.introBg}`}
        ref={videoRef}
        src={post.bgGIF?.asset?.url}
        alt=""
      />
    </div>
  );
};

// Registration Screen Component
const RegisterScreen: React.FC<{
  post: any;
  onSubscribe: () => void;
  isAudioReady: boolean;
}> = ({ post, onSubscribe, isAudioReady }) => {
  const nextDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    gsap.fromTo(
      nextDivRef.current,
      { opacity: 0 },
      { opacity: 1, duration: 1 }
    );
  }, []);

  return (
    <div className={styles.nextDiv} ref={nextDivRef}>
      <div className={styles.contentArea}>
        <div className={styles.innerContentArea}>
          <h1>
            Register now and discover a flash of my new song "{post?.title}"
          </h1>
          <SimpleSubscriber
            onSubscribe={onSubscribe}
            disabled={!isAudioReady}
          />
          <p className={styles.terms}>
            By clicking submit you confirm you are over 15 y.o. and accept to
            receive news about INDIECLIMB
          </p>
        </div>
      </div>
    </div>
  );
};

// Subscription Success Screen
const SubscriptionSuccessScreen: React.FC<{
  audioRef: React.RefObject<HTMLAudioElement>;
  currentSeconds: number;
  totalSeconds: number;
  post: any;
  onFadeIn: () => void;
}> = ({ post, currentSeconds, totalSeconds, onFadeIn }) => {
  const barsRef = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const randomOrder = gsap.utils.shuffle([...barsRef.current]);

    gsap.to(randomOrder, {
      height: () => `${Math.random() * 10 + 5}px`, // Random height between 20px and 100px
      duration: 0.2,
      repeat: -1,
      ease: "power1.inOut",
      stagger: {
        each: 0.01, // Time between starting the next bar
        from: "start", // Start from the first bar in the randomized array
      },
      repeatRefresh: true, // Ensures randomness on every repeat
    });
  }, []);

  // Trigger fade-in only once on component mount
  useEffect(() => {
    onFadeIn();
  }, []); // Empty dependency array ensures this runs only once

  const currentSecondsInner = (currentSeconds / totalSeconds) * 100;

  return (
    <div className={styles.listening}>
      <div
        className={styles.progressBar}
        style={{
          width: `${currentSecondsInner}%`,
        }}
      ></div>

      <div className={styles.text}>
        <div className={styles.innerText}>
          <p>Now playing </p>
          <div className={styles.visualizer}>
            {Array.from({ length: 5 }).map((_, index) => (
              <div
                key={index}
                ref={(el) => (barsRef.current[index] = el)} // Add each bar to the ref array
                className={styles.bar}
              ></div>
            ))}
          </div>
        </div>
        <h1>{post.title}</h1>
      </div>
      <img src={post.bgGIF?.asset?.url} className={styles.bgGif} alt="" />
    </div>
  );
};

// Final Screen
const FinalScreen: React.FC<{ post: any }> = ({ post }) => {
  const finalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    gsap.fromTo(finalRef.current, { opacity: 0 }, { opacity: 1, duration: 1 });
  }, []);

  return (
    <div className={styles.finalScreen} ref={finalRef}>
      <h3 className={styles.nameTitle}>Indieclimb</h3>
      <img src={post.coverImage?.asset?.url} alt="" />
      <h2 className={styles.skinnyHead}>
        <RegularBlinker speed={1000}>
          New single
          <br />
          Out {formatDateWithOrdinal(post.releaseDate)}
        </RegularBlinker>
      </h2>
    </div>
  );
};
