import React, { useState } from "react";
import styles from "./SimpleSubscriber.module.scss";

interface SimpleSubscriberProps {
  onSubscribe: () => void; // Callback to notify successful subscription
  disabled: boolean;
}

export const SimpleSubscriber: React.FC<SimpleSubscriberProps> = ({
  onSubscribe,
  disabled,
}) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [successfullySubscribed, setSuccessfullySubscribed] = useState(false);

  // Email validation function
  const isEmailValid = (email: string): boolean => {
    const regex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return regex.test(email);
  };

  const handleSubscribe = async () => {
    // Validate the email before making the request
    if (!isEmailValid(email) && email !== "test") {
      setMessage("Invalid email address");
      return;
    }

    // Handle the "test" case for a successful subscription
    if (email === "test") {
      setSuccessfullySubscribed(true);
      setMessage("Subscription successful!");
      setEmail("");

      // Notify parent component
      onSubscribe();
      return;
    }

    // Indicate loading state
    setLoading(true);
    setMessage("");

    try {
      const response = await fetch(
        "https://limitless-fjord-09502-12e51f39ac59.herokuapp.com/subscribe",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
          mode: "cors",
        }
      );

      // Handle unsuccessful responses
      if (!response.ok && response.status !== 400) {
        const errorData = await response.json();
        setMessage(`Error: ${errorData.error}`);
        return;
      }

      // Success
      setSuccessfullySubscribed(true);
      setMessage("Subscription successful!");
      setEmail("");

      // Notify parent component
      onSubscribe();
    } catch (error) {
      setMessage("An error occurred while subscribing.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.submitArea}>
      {!successfullySubscribed ? (
        <>
          <input
            type="email"
            placeholder="Your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading}
            autoComplete="on"
            className={styles.input}
          />
          <button
            onClick={handleSubscribe}
            disabled={disabled}
            className={styles.submitButton}
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
        </>
      ) : (
        <p className={styles.successMessage}>Thank you for subscribing!</p>
      )}
      {message && (
        <p
          style={{
            marginTop: "10px",
            color: message.includes("successful") ? "green" : "red",
          }}
        >
          {message}
        </p>
      )}
    </div>
  );
};
