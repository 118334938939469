import React, { useState, useEffect } from "react";
import styles from "./PreSave.module.scss";
import { Countdown } from "../../components/Countdown/Countdown.component";
import Cookies from "js-cookie";
import { SaveIcon } from "../../components/SaveIcon/SaveIcon.component";
import { InteractiveButton } from "../../components/InteractiveButton/InteractiveButton.component";

const CLIENT_ID = "9be629527f294628bfccf433c2271079";
const REDIRECT_URI = "https://indieclimb.com/en/pre-save/legacy";
const SPOTIFY_SCOPES = encodeURIComponent(
  "user-library-modify user-library-read playlist-modify-public playlist-modify-private user-follow-modify"
);

const RELEASE_DATE = new Date("2025-03-28T00:00:00Z");
const AUTH_URL = `https://accounts.spotify.com/authorize?client_id=${CLIENT_ID}&response_type=token&redirect_uri=${encodeURIComponent(
  REDIRECT_URI
)}&scope=${SPOTIFY_SCOPES}`;

const ALBUM_NAME = "Legacy";
const ARTIST_NAME = "Indieclimb";
const ARTIST_ID = "5wk5mLirrHPNbkvor0f5S2";
const PRE_RELEASE_ALBUM_ID = "79qyRumUZvylQZChU5XwZj";

export const PreSave: React.FC<{ siteSettings: any; currentLang: string }> = ({
  siteSettings,
  currentLang,
}) => {
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [isFollowing, setIsFollowing] = useState<boolean>(false);

  const [isReleased, setIsReleased] = useState(new Date() >= RELEASE_DATE);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [albumId, setAlbumId] = useState<string | null>(PRE_RELEASE_ALBUM_ID);
  const [playlists, setPlaylists] = useState<any[]>([]);
  const [selectedPlaylist, setSelectedPlaylist] = useState<string>("");
  const [justLoggedIn, setJustLoggedIn] = useState(false);

  const checkIfAlbumIsSaved = async () => {
    let token = accessToken || Cookies.get("spotify_access_token");
    if (!token) {
      console.warn("⚠️ No access token found. Redirecting to login...");
      Cookies.remove("spotify_access_token");
      window.location.replace(AUTH_URL);
      return;
    }

    try {
      const response = await fetch(
        `https://api.spotify.com/v1/me/albums/contains?ids=${albumId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 401) {
        console.error("❌ Token expired. Redirecting to login...");
        Cookies.remove("spotify_access_token");
        window.location.replace(AUTH_URL);
        return;
      }

      if (!response.ok) throw new Error("Failed to check album status");

      const data = await response.json();
      setIsSaved(data[0]);
      localStorage.setItem("album_saved", data[0] ? "true" : "false");
    } catch (error) {
      console.error("❌ Error checking album status:", error);
    }
  };

  const checkIfUserFollowsArtist = async () => {
    if (!accessToken) return;

    try {
      const response = await fetch(
        `https://api.spotify.com/v1/me/following/contains?type=artist&ids=${ARTIST_ID}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) throw new Error("Failed to check follow status");

      const data = await response.json();
      setIsFollowing(data[0]); // true if the user follows, false otherwise
    } catch (error) {
      console.error("❌ Error checking follow status:", error);
    }
  };

  useEffect(() => {
    const savedState = localStorage.getItem("album_saved");
    if (savedState === "true") {
      setIsSaved(true);
    }

    const tokenFromCookie = Cookies.get("spotify_access_token");
    if (tokenFromCookie) {
      console.log("✅ Access token found in cookies.");
      setAccessToken(tokenFromCookie);
    } else {
      const hash = window.location.hash.substring(1);
      const params = new URLSearchParams(hash);
      const token = params.get("access_token");

      if (token) {
        console.log("✅ New access token retrieved:", token);
        Cookies.set("spotify_access_token", token, { expires: 1 / 24 });
        setAccessToken(token);
        setJustLoggedIn(true);
        window.history.replaceState(null, "", window.location.pathname);
      }
    }
  }, []);

  useEffect(() => {
    if (accessToken) {
      console.log(
        "📡 Token is available, fetching album status and playlists..."
      );
      checkIfAlbumIsSaved();
      checkIfUserFollowsArtist(); // <-- Call this function
      fetchUserPlaylists();
    }
  }, [accessToken]);

  useEffect(() => {
    if (isReleased && accessToken) {
      fetchFinalAlbumId();
    }
  }, [isReleased, accessToken]);

  useEffect(() => {
    if (justLoggedIn && accessToken) {
      handlePreSave();
      setJustLoggedIn(false);
    }
  }, [justLoggedIn, accessToken]);

  const fetchFinalAlbumId = async () => {
    if (!accessToken) return;
    try {
      const response = await fetch(
        `https://api.spotify.com/v1/search?q=album:${encodeURIComponent(ALBUM_NAME)}+artist:${encodeURIComponent(
          ARTIST_NAME
        )}&type=album&limit=1`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      const data = await response.json();
      if (data.albums?.items?.length > 0) {
        const newAlbumId = data.albums.items[0].id;
        setAlbumId(newAlbumId);
        return newAlbumId;
      }
    } catch (error) {
      console.error("Error fetching final album ID:", error);
    }
  };

  const fetchUserPlaylists = async () => {
    if (!accessToken) return;
    try {
      const response = await fetch("https://api.spotify.com/v1/me/playlists", {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      const data = await response.json();
      setPlaylists(data.items);
    } catch (error) {
      console.error("Error fetching playlists:", error);
    }
  };

  const handlePreSave = async () => {
    if (!accessToken) {
      window.location.replace(AUTH_URL);
      return;
    }

    try {
      const response = await fetch(
        `https://api.spotify.com/v1/me/albums?ids=${albumId}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        setIsSaved(true);
        localStorage.setItem("album_saved", "true");
      }
    } catch (error) {
      console.error("Error saving album:", error);
    }
  };

  const handleFollowArtist = async () => {
    if (!accessToken) return;

    setIsFollowing(false); // Reset before making request

    // Create a timeout and store its reference
    const timeout = setTimeout(() => {
      setIsFollowing(false); // If no success, show error
    }, 5000);

    try {
      const response = await fetch(
        `https://api.spotify.com/v1/me/following?type=artist&ids=${ARTIST_ID}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        console.log("✅ Artist followed successfully!");
        clearTimeout(timeout); // ✅ Cancel the error timeout
        setIsFollowing(true); // ✅ Update state to success
        return;
      }
    } catch (error) {
      console.error("❌ Error following artist:", error);
    }

    // If the request fails, it will hit this point and trigger error state
  };

  const handleAddToPlaylist = async () => {
    if (!accessToken || !selectedPlaylist) return;

    try {
      // Fetch the album's tracks
      const tracksResponse = await fetch(
        `https://api.spotify.com/v1/albums/${albumId}/tracks`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      const tracksData = await tracksResponse.json();

      if (!tracksData.items || tracksData.items.length === 0) {
        console.error("No tracks found in album.");
        return;
      }

      // Extract track URIs
      const trackUris = tracksData.items.map((track: any) => track.uri);

      // Add tracks to playlist
      const addTracksResponse = await fetch(
        `https://api.spotify.com/v1/playlists/${selectedPlaylist}/tracks`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ uris: trackUris }),
        }
      );

      if (addTracksResponse.ok) {
        console.log("Album added to playlist successfully!");
      } else {
        const errorData = await addTracksResponse.json();
        console.error("Error adding album to playlist:", errorData);
      }
    } catch (error) {
      console.error("Error adding album to playlist:", error);
    }
  };

  return (
    <div
      className={styles.preSave}
      style={{
        background:
          'url("https://cdn.sanity.io/images/m4jodx2s/production/528c74a56a5e6577c5d0ab03e30feb0d1cc6e2ec-800x450.gif")',
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className={styles.presaveTop}>
        <h1 className={styles.songTitle}>LEGACY</h1>
        <h3 className={styles.artistName}>OUT NOW</h3>
      </div>
      <div className={styles.presaveBottom}>
        <div className={styles.presaveForm}>
          {/* AFTER STYLING CHANGE TO isSaved! */}
          {isSaved ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className={styles.presaveFormRow}>
                <button
                  className={`${styles.presaveFormButton} ${styles.saved}`}
                  disabled
                >
                  saved
                </button>
                <SaveIcon checked={!isSaved} />
              </div>

              <br />
              <div className={styles.presaveFormRow}>
                {/* <button
                  className={`${styles.presaveFormButton} ${styles.follow}`}
                  onClick={handleFollowArtist}
                >
                  FOLLOW
                </button> */}
              </div>
              <br />
              <InteractiveButton
                onClick={handleFollowArtist}
                success={isFollowing}
                text="Follow"
              />
              <br />
              <select
                className={styles.playlistDropdown}
                value={selectedPlaylist}
                onChange={(e) => {
                  setSelectedPlaylist(e.target.value);
                  handleAddToPlaylist();
                }}
              >
                <option value="">Add to your Playlist</option>
                {playlists.map((playlist) => (
                  <option key={playlist.id} value={playlist.id}>
                    {playlist.name}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <button
              className={`${styles.presaveFormButton} ${styles.preSave}`}
              onClick={handlePreSave}
            >
              {isReleased ? "SAVE HERE" : "PRE-SAVE HERE"}

              <img src="/images/icons/add.svg" alt="Add icon" />
            </button>
          )}
        </div>
      </div>
      <div className={styles.counterSection}>
        {<Countdown date={RELEASE_DATE} />}
      </div>
    </div>
  );
};
