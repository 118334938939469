import React, { useEffect, useState } from "react";
import "./App.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next"; // Importing useTranslation hook
import { Home } from "./pages/Home/home.page";
import { Navbar } from "./components/Navbar/navbar.component";
import { ProductPageWrapper, Shop } from "./pages/Shop/shop.page";
import { ShopNavbar } from "./components/ShopNavbar/shopNavbar.component";
import { Newsletter } from "./pages/Newsletter/Newsletter.component";
import { AdPage } from "./pages/LandingPages/LetMeGo/AdPage.page";
import { Adbar } from "./components/Adbar/Adbar.component";
import { Dayplan } from "./pages/Dayplan/Dayplan.page";
import { Planbar } from "./components/Planbar/Planbar.component";
import ProjectPage from "./pages/ProjectPage/ProjectPage.page";
import { HelmetProvider } from "react-helmet-async";
import { client } from "./sanityClient";
import { Application } from "./pages/Application/Application.component";
import { DownloadPage } from "./pages/DownloadsPage/DownloadPage.component";
import { SongRegister } from "./pages/SongRegister/SongRegister.component";
import { PreSave } from "./pages/PreSave/PreSave.component";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentLang, setCurrentLang] = useState<string>("en");

  useEffect(() => {
    // Check if the pathname is just '/' or effectively empty
    if (location.pathname === "/") {
      navigate("/en/");
    }
  }, [navigate, location.pathname]);
  const { i18n } = useTranslation(); // Using i18next in the component
  const supportedLanguages =
    process.env.REACT_APP_SUPPORTED_LANGUAGES?.split(",");

  useEffect(() => {
    const pathParts = location.pathname.split("/");
    // Determine the position of the language code based on the number of segments in the URL

    const potentialLang = pathParts[1];
    if (potentialLang && supportedLanguages?.includes(potentialLang)) {
      i18n.changeLanguage(potentialLang);
    }
    setCurrentLang(potentialLang);
  }, [location, i18n]);

  useEffect(() => {
    const pathParts = location.pathname.split("/");
    if (
      pathParts[1] === "operation" ||
      (pathParts[1] === "en" && pathParts[2] === "operation")
    ) {
      document.documentElement.style.background = "blue";
    } else {
    }

    // Clean up by resetting the overflow style when the component unmounts or path changes
    return () => {
      document.body.style.maxHeight = "auto";
    };
  }, [location]);

  const [settings, setSettings] = useState<any | null>(null);

  useEffect(() => {
    async function fetchFrontPage() {
      const query = `*[_id == "siteSettings"]{
          siteTitle,
          primaryColor,
          secondaryColor,
          typography,
          spacing,
          customCSS
        }[0]`;

      try {
        const frontPageData = await (client as any).fetch(query);

        setSettings(frontPageData); // Set the post state with the fetched data
      } catch (error) {
        console.error("Error fetching front page data:", error);
      }
    }

    fetchFrontPage(); // Call the fetch function when component mounts
  }, []); // Empty dependency array, since no slug is needed

  if (!settings) return <p>Loading...</p>; // Display loading text while data is being

  const renderNavbar = () => {
    const pathParts = location.pathname.split("/");

    // Check if the route is for a new release
    if (
      (pathParts[2] && pathParts[2] === "release") || // Language-specific new release
      (pathParts[1] && pathParts[1] === "release") || // Default new release
      (pathParts[1] && pathParts[1] === "pre-save") || // Default pre-save
      (pathParts[2] && pathParts[2] === "pre-save") // Language-specific pre-save
    ) {
      return null; // Do not render the navbar
    }

    if (
      pathParts[1] === "operation" ||
      (pathParts[1] === "en" && pathParts[2] === "operation")
    ) {
      return <Planbar />;
    } else if (pathParts[1] === "shop") {
      return <ShopNavbar />;
    } else if (pathParts[2] === "ad") {
      return <Adbar />;
    } else if (pathParts[2] === "application") {
      return null;
    } else {
      return <Navbar settings={settings} />;
    }
  };

  return (
    <div>
      <HelmetProvider>
        {renderNavbar()}

        <Routes>
          <Route path=":lang/" element={<Home siteSettings={settings} />} />
          <Route path="/" element={<Home siteSettings={settings} />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/shop/:productId" element={<ProductPageWrapper />} />
          <Route path=":lang/newsletter/" element={<Newsletter />} />
          <Route path="/newsletter" element={<Newsletter />} />
          <Route
            path=":lang/ad/:slug"
            element={<AdPage currentLang={currentLang} />}
          />
          <Route
            path="/ad/:slug"
            element={<AdPage currentLang={currentLang} />}
          />
          <Route
            path=":lang/epk/:slug"
            element={<ProjectPage currentLang={currentLang} />}
          />
          <Route
            path=":lang/application/:slug"
            element={
              <Application siteSettings={settings} currentLang={currentLang} />
            }
          />
          <Route
            path="/application/:slug"
            element={
              <Application siteSettings={settings} currentLang={currentLang} />
            }
          />

          <Route
            path="/application/:slug"
            element={
              <Application siteSettings={settings} currentLang={currentLang} />
            }
          />
          <Route
            path="/pre-save/:title"
            element={
              <PreSave siteSettings={settings} currentLang={currentLang} />
            }
          />
          <Route
            path=":lang/pre-save/:title"
            element={
              <PreSave siteSettings={settings} currentLang={currentLang} />
            }
          />
          <Route path="/release/:slug" element={<SongRegister />} />
          <Route path=":lang/release/:slug" element={<SongRegister />} />

          <Route
            path=":lang/operation/:projectId"
            element={<Dayplan currentLang={currentLang} />}
          />
          <Route
            path="/operation/:projectId"
            element={<Dayplan currentLang={currentLang} />}
          />
        </Routes>
      </HelmetProvider>
    </div>
  );
}

export default App;
