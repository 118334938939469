import React, { useState, useEffect } from "react";
import styles from "./InteractiveButton.module.scss";
import { SaveIcon } from "../SaveIcon/SaveIcon.component"; // Adjust path as needed

interface InteractiveButtonProps {
  onClick?: () => void;
  text?: string;
  type?: "primary" | "secondary";
  disabled?: boolean;
  success: boolean; // Controlled externally
}

export const InteractiveButton: React.FC<InteractiveButtonProps> = ({
  onClick,
  text = "Button",
  type = "primary",
  disabled = false,
  success,
}) => {
  const [currentState, setCurrentState] = useState<
    "idle" | "loading" | "success" | "error"
  >("idle");

  useEffect(() => {
    if (success) {
      setCurrentState("success"); // ✅ If `success` is true, switch to "success"
    }
  }, [success]);

  const handleClick = () => {
    if (disabled || currentState === "loading") return;

    setCurrentState("loading"); // Start loading
    onClick?.(); // Trigger the parent function

    // Set a timeout: If success doesn't happen within 5s, switch to "error"
    setTimeout(() => {
      if (!success) setCurrentState("error");
    }, 5000);
  };

  // Determine button styles based on state
  const buttonClass = `
    ${styles.interactiveButton} 
    ${styles[type]} 
    ${currentState === "error" ? styles.error : ""}
    ${currentState === "success" ? styles.success : ""}
    ${currentState === "loading" ? styles.loading : ""}
    ${disabled ? styles.disabled : ""}
  `;

  return (
    <button
      className={buttonClass}
      onClick={handleClick}
      disabled={disabled || currentState === "loading"}
    >
      <span>
        {currentState === "error"
          ? "Error"
          : currentState === "loading"
            ? "Loading..."
            : text}
      </span>

      <SaveIcon checked={currentState === "success"} />
    </button>
  );
};
